<template>
  <div class="weekly-sales">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item
          class="search-form-gutter"
          :label="$t('analysis.platform')"
        >
          <el-select
            style="width: 88px"
            v-model="listQuery.platform"
            @change="changePlatform"
            filterable
          >
            <el-option
              v-for="item in platformList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-select
            style="width: 108px"
            v-model="listQuery.minDate"
            @change="fetchBrands"
            filterable
            :disabled="dateLoading"
            v-loading="dateLoading"
          >
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <span class="date-gap"> ~ </span>
          <el-select
            style="width: 108px"
            v-model="listQuery.maxDate"
            @change="fetchBrands"
            filterable
            :disabled="dateLoading"
            v-loading="dateLoading"
          >
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.brand')">
          <el-select
            style="width: 128px"
            v-model="listQuery.brandId"
            clearable
            filterable
            :disabled="brandLoading"
            v-loading="brandLoading"
            :placeholder="$t('common.all')"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.product')">
          <el-radio-group v-model="listQuery.isFashion">
            <el-radio-button :label="true">Fashion</el-radio-button>
            <el-radio-button :label="false">Etc</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button
            size="small"
            :circle="true"
            type="primary"
            @click="fetchData"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
      </el-form>
      <div class="download-btn">
        <el-tooltip
          class="item"
          effect="dark"
          content="Download Excel"
          placement="bottom"
        >
          <el-button
            size="small"
            :circle="true"
            type="success"
            class="excel-dl-btn"
            @click="clickDownload"
            icon="el-icon-download"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="sales-trend-filter">
      <el-form :inline="true" size="small">
        <el-form-item
          class="search-form-gutter"
          :label="$t('analysis.graph')"
          v-if="false"
        >
          <el-radio-group v-model="currentGraph">
            <el-radio label="fixed">{{ $t("analysis.fixedBar") }}</el-radio>
            <el-radio label="percentage">{{
              $t("analysis.percentageBar")
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.item')">
          <el-radio-group v-model="distinguishItem">
            <el-radio :label="true">{{ $t("common.distinguish") }}</el-radio>
            <el-radio :label="false">{{
              $t("common.notDistinguish")
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('analysis.criterion')"
        >
          <el-radio-group v-model="currentCriterion">
            <el-radio label="sales">{{ $t("analysis.sales") }}</el-radio>
            <el-radio label="salesVolume">{{
              $t("analysis.salesVolume")
            }}</el-radio>
            <el-radio label="style">{{ $t("analysis.styles") }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div v-loading="chartLoading" id="sales-trend-chart" />
  </div>
</template>

<script>
import * as echarts from "echarts";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Guide from "@/components/Guide";
import { downloadFileAuth } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import {
  fetchUserBrands,
  fetchWeeklySalesTrend,
  fetchRecords,
} from "@/api/analysis";

export default {
  components: {
    AiBreadcrumb,
    Guide,
  },
  data() {
    return {
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      dateList: [],
      brandList: [],
      chart: null,
      tableData: [],
      listQuery: {
        period: "week",
        platform: "tmall",
        minDate: "",
        maxDate: "",
        brandId: null,
        isFashion: true,
      },
      lastQuery: null,
      currentGraph: "fixed",
      currentCriterion: "sales",
      distinguishItem: true,
      firstLoad: false,
      dateLoading: false,
      chartLoading: false,
      brandLoading: false,
    };
  },
  mounted() {
    this.firstLoad = false;
    window.addEventListener("resize", () => {
      if (this.chart) {
        this.chart.resize();
      }
    });
    this.fetchDates();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.chart.resize);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    fetchDates() {
      this.listQuery.minDate = this.listQuery.maxDate = "";
      this.dateList = [];
      this.dateLoading = true;
      fetchRecords({
        type: "sales",
        period: this.listQuery.period,
        platform: this.listQuery.platform,
        limit: -1,
      })
        .then((response) => {
          if (response.success) {
            response.result &&
              response.result.items &&
              response.result.items.forEach((e) => {
                this.dateList.push({
                  value: e.date,
                  label: e.linkDate,
                });
              });
            if (this.dateList && this.dateList.length > 0) {
              if (this.dateList.length > 5) {
                this.listQuery.minDate = this.dateList[5].value;
              } else {
                this.listQuery.minDate =
                  this.dateList[this.dateList.length - 1].value;
              }
              this.listQuery.maxDate = this.dateList[0].value;
              this.fetchBrands();
            }
          }
          this.dateLoading = false;
        })
        .catch(() => {
          this.dateLoading = false;
        });
    },
    fetchBrands() {
      this.listQuery.brandId = null;
      this.brandList = [];
      if (!this.listQuery.minDate || !this.listQuery.maxDate) {
        return;
      }
      if (this.listQuery.minDate > this.listQuery.maxDate) {
        this.$message({
          message: "Min date must not greater than max date",
          type: "warning",
        });
        return;
      }

      let dates = [];
      this.dateList.forEach((t) => {
        if (
          t.value >= this.listQuery.minDate &&
          t.value <= this.listQuery.maxDate
        ) {
          dates.push(t.value);
        }
      });
      this.brandLoading = true;
      fetchUserBrands({
        platform: this.listQuery.platform,
        dates: dates.join(","),
      })
        .then((response) => {
          if (response.success) {
            this.brandList =
              response.result && response.result.items
                ? response.result.items
                : [];
            if (!this.firstLoad) {
              this.firstLoad = true;
              this.fetchData();
            }
          }
          this.brandLoading = false;
        })
        .catch(() => {
          this.brandLoading = false;
        });
    },
    fetchData() {
      if (!this.listQuery.minDate || !this.listQuery.maxDate) {
        this.$message({
          message: "Please select both min date and max date first",
          type: "warning",
        });
        return;
      }
      if (this.listQuery.minDate > this.listQuery.maxDate) {
        this.$message({
          message: "Min date must not greater than max date",
          type: "warning",
        });
        return;
      }
      let dateCnt = 0;
      this.dateList.forEach((t) => {
        if (
          t.value >= this.listQuery.minDate &&
          t.value <= this.listQuery.maxDate
        ) {
          dateCnt++;
        }
      });
      if (dateCnt > 12) {
        this.$message({
          message: "Please select at most 12 dates interval",
          type: "warning",
        });
        return;
      }
      this.chartLoading = true;
      this.lastQuery = JSON.parse(JSON.stringify(this.listQuery));
      fetchWeeklySalesTrend({
        ...this.listQuery,
        distinguishItem: this.distinguishItem,
      })
        .then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.drawChart();
          }
          this.chartLoading = false;
        })
        .catch(() => {
          this.chartLoading = false;
        });
    },
    drawChart() {
      if (this.chart) {
        this.chart.dispose();
      }
      let option = {
        grid: {
          top: 20,
          left: 5,
          bottom: 5,
          right: 150,
          containLabel: true,
        },
        tooltip: {},
        legend: {
          type: "scroll",
          orient: "vertical",
          top: "center",
          right: 5,
          data: [],
          reversed: true,
          selector: [
            {
              type: "all",
              title: this.$t("common.selectAll"),
            },
            {
              type: "inverse",
              title: this.$t("common.selectReverse"),
            },
          ],
        },
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            interval: 0,
          },
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
          max: null,
        },
        series: [],
      };
      if (this.currentGraph == "percentage") {
        option.yAxis.axisLabel.formatter = "{value} %";
        option.yAxis.max = "100";
      }
      let totalCnt = {};
      let getVal = (d) => {
        switch (this.currentCriterion) {
          case "sales":
            return d["sumSale"] ? d.sumSale : 0;
          case "salesVolume":
            return d["sumSaleQtys"] ? d.sumSaleQtys : 0;
          case "style":
            return d["sumStyles"] ? d.sumStyles : 0;
        }
      };
      this.tableData.forEach((e) => {
        option.xAxis.data.push(e.linkDate);
        totalCnt[e.date] = 0;
        e.detail &&
          e.detail.forEach((d) => {
            if (this.currentGraph == "percentage") {
              totalCnt[e.date] += getVal(d);
            }
            for (let i = 0; i < option.series.length; i++) {
              if (option.series[i].name === d.item) {
                return;
              }
            }
            option.series.push({
              name: d.item,
              type: "bar",
              stack: "sales",
              data: [],
              barMaxWidth: "40%",
            });
            option.legend.data.push(d.item);
          });
      });
      option.series.forEach((s) => {
        this.tableData.forEach((e) => {
          let v = 0;
          e.detail &&
            e.detail.forEach((d) => {
              if (d.item === s.name) {
                if (this.currentGraph == "percentage") {
                  v =
                    Math.floor((getVal(d) / totalCnt[e.date]) * 100 * 100) /
                    100;
                } else {
                  v = getVal(d);
                }
              }
            });
          s.data.push(v);
        });
      });
      if (!this.distinguishItem) {
        option.legend.show = false;
      }
      this.chart = echarts.init(document.getElementById("sales-trend-chart"));
      this.chart.setOption(option, true);
    },
    clickDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      this.lastQuery.brandId = this.lastQuery.brandId
        ? this.lastQuery.brandId
        : 0;
      let queryStr = new URLSearchParams(this.lastQuery).toString();
      downloadFileAuth(
        aiApiRoot + "/v1/analysis/weekly-sales-trend/export?" + queryStr,
        "weekly-sales-trend-" +
          this.lastQuery.minDate +
          "-" +
          this.lastQuery.maxDate +
          "-" +
          this.lastQuery.brandId +
          "-" +
          this.lastQuery.isFashion +
          ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
    changePlatform() {
      this.fetchDates();
    },
  },
  watch: {
    distinguishItem() {
      this.fetchData();
    },
    currentGraph() {
      this.drawChart();
    },
    currentCriterion() {
      this.drawChart();
    },
  },
};
</script>

<style lang="scss" scoped>
.weekly-sales {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .toolbar {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-form {
      float: left;
    }

    .download-btn {
      display: inline-block;
    }

    .date-gap {
      font-weight: bold;
      margin: 0 5px;
    }
  }

  .sales-trend-filter {
    padding: 0 10px 10px 10px;
    width: 100%;

    /deep/ .el-form-item {
      margin-bottom: 0;
    }

    .search-form-gutter {
      margin-right: 40px;
    }
  }

  #sales-trend-chart {
    width: 100%;
    height: calc(100vh - 100px - 52.5px - 52.5px - 42px - 10px - 10px);
  }
}
</style>